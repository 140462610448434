<template>
  <div>
  <v-progress-circular v-if="!loaded" indeterminate></v-progress-circular>
  <ImageComponent v-if="loaded" :data="images"></ImageComponent>
  </div>
</template>

<script>
import ImageComponent from '@/components/ImageComponent.vue';
import { s3, bucketName, hochzeitKategorie, encode } from '../service/awsApi.js'
export default {
name: "HochzeitView",
data: () => ({
  images: [],
  loaded: Boolean,
}),
components: { ImageComponent },
async mounted() {
  this.loaded = false;
  try {
    let imagePaths = []
    await s3.listObjectsV2(hochzeitKategorie, async(err, data)=> {
      if (err) {
        console.log(err, err.stack); // an error occurred
      }
      else {
        imagePaths = data.Contents; 
        imagePaths.shift();
        let imageParameter = {
          Bucket: bucketName,
          Key: imagePaths,
        }
        for (let path of imagePaths) {
          imageParameter.Key = path.Key;
          await s3.getObject(imageParameter, (err, img) => {
            this.images.push("data:image/jpeg;base64," + encode(img.Body));
          });
        }
        this.loaded = true; 
      }
    });
  } catch (error) {
    console.error('Error fetching image URL:', error);
  }
},
}
</script>