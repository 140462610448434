<template> 
<div>
  <div>
<v-row no-gutters>
  <v-carousel
  hide-delimiters
  hide-delimiter-background
  show-arrows-on-hover
  height="15%"
  class="rounded-xl"
  >
      <v-carousel-item       reverse-transition="fade-transition"
      transition="fade-transition">
        <v-img :src="require(`@/assets/slide2.jpg`)" class="carouselitem"></v-img>
      </v-carousel-item >
      <v-carousel-item       reverse-transition="fade-transition"
      transition="fade-transition">
        <v-img :src="require(`@/assets/slide4.jpg`)" class="carouselitem"></v-img>
      </v-carousel-item>
      <v-carousel-item       reverse-transition="fade-transition"
      transition="fade-transition">
        <v-img :src="require(`@/assets/slide6.jpg`)" class="carouselitem"></v-img>
      </v-carousel-item>
    </v-carousel>
    </v-row>
  </div>

    <v-row dense>
    
      <div class="text-h3 category">
      <h3>Galerie</h3>
      </div>
    </v-row>

    <v-row no-gutter>
        <GaleryCard :data="categories"></GaleryCard>
    </v-row>

    <v-row dense>
      
      <div class="text-h3  category">
      <h3>Kontakt</h3>
    </div>
    </v-row>

    <v-row dense>
        <StreetView></StreetView>
    </v-row>
   
  </div> 
</template>

<script>
import GaleryCard from '@/components/GaleryCard.vue';
import StreetView from '@/components/StreetView.vue';
  export default {
    name: "HomeView",
    data() {
        return {
            carouselItems: [
                "slide2.jpg",
                "slide3.jpg",
                "slide4.jpg",
                "slide6.jpg",
            ],
            categories: [
                  {
                    name: 'Babys',
                    img: 'babygalerie.jpeg',
                    link: '/babys'
                  },
                  {
                    name: 'Babybauch',
                    img: 'babybauch.jpg',
                    link: '/babybauch'
                  },
                  {
                    name: 'Familie',
                    img: 'familie.jpg',
                    link: '/familie'
                  },
                  {
                    name: 'Hochzeit',
                    img: 'hochzeit.jpg',
                    link: '/hochzeit'
                  },
                ]
        };
    },
    components: {  GaleryCard, StreetView }
}
</script>

<style>

.category {
  padding-top: 5px;
}

.image-slider {
  padding-top: 5px;
}


@media screen and (max-width: 600px) {
  h3 {
    font-size: 0.8em;
    font-style: oblique;
  }
}

@media screen and (min-width: 600px) {
  h3 {
    font-size: 0.8em;
    font-style: oblique;
  }
}

</style>