<template>
  <div>
    <v-btn class="desktop button" left absolute @click="back()"> 
        <v-icon
        dark
        left
        >
        mdi-arrow-left
      </v-icon></v-btn>
    <v-progress-circular v-if="!loaded" indeterminate></v-progress-circular>
    <BabyCard  v-if="loaded" :data="babyImages" :key="rerender"></BabyCard>
  </div>
</template>


<script>
import BabyCard from '@/components/BabyCard.vue';
import {s3, babyKategorie, BabyPictures, encode } from '../service/awsApi.js'
  export default {
    name: "BabyView",
    data: () => ({
      babyImages: [],
      loaded: Boolean,
      reload: true,
      initResp: [],
      rerender: 0,
    }),
    watch:{
    async $route (to){
      if(to.path == `/babys`){
        try {
          await s3.getObject(babyKategorie,async (err, data) => {
          if (err) {
            console.error('Error retrieving object:', err);
          } else {
            const babyResponse = JSON.parse(data.Body).reverse();
            if(this.initResp.length != babyResponse.length){
              this.babyImages = [];
              for(const image of babyResponse){
                BabyPictures.Key = image.link.toString();
                await s3.getObject(BabyPictures,(err, img) => {
                     image.link = "data:image/jpeg;base64," + encode(img.Body);
                    })
                    this.babyImages.push(image);
              }
              this.rerender += 1;
              this.loaded=true;
            }
            }
          });
        } catch (error) {
          console.error('Error fetching image URL:', error);
          // Handle the error here
        }
      }
    },
}, 
    components: { BabyCard },
    async mounted() {
      this.loaded= false;
      try {
        await s3.getObject(babyKategorie,async (err, data) => {
        if (err) {
          console.error('Error retrieving object:', err);
        } else {
          const babyResponse = this.initResp = JSON.parse(data.Body).reverse();
          if(!this.babyImages.length){
            for(const image of babyResponse){
              BabyPictures.Key = image.link.toString();
              await s3.getObject(BabyPictures,(err, img) => {
                   image.link = "data:image/jpeg;base64," + encode(img.Body);
                  })
                  this.babyImages.push(image);
            }
            this.loaded=true;
          }
          }
        });
      } catch (error) {
        console.error('Error fetching image URL:', error);
        // Handle the error here
      }
  },
  methods:{
      back (){
        this.$router.back();
      }
    }
}
</script>