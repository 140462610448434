<template>
      <div>
          <v-container fluid>
            <v-row dense style="padding-bottom: 5vh;">
              <v-col lg="2" class="desktop">
          <v-img src="../assets/lkh_logo.png"></v-img>
        </v-col>
        <v-col xs="12" md="12" lg="8">
          <introCard></introCard>
        </v-col>
      <v-spacer class="desktop"></v-spacer>
      <v-col xs="12" md="12" class="phone">
        <v-img src="../assets/lkh_logo.png"></v-img>
      </v-col>
    
    </v-row>
        <v-row
          dense
        >
          <v-col 
          v-for="(item, index) in babycards" :key="index"
          xs="12" md="4" lg="3"
          style="padding-bottom: 5vh;"
          >          
          <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-card
              :style="{'background-color': item.type ? '#fff5ff':'#e6f2ff'}"
              :class="{ 'on-hover': hover }"
              class="rounded-xl"
              :elevation="hover ? 12 : 2"
              shaped
              @click="babyview(item.id)"
              v-bind="attrs"
                v-on="on"
            >
              <v-img
              :src="item.link"
              max-width="100vw"
              max-heigth="100vh"
              ></v-img>
              <v-card-title centered>
                {{ item.name }}
              </v-card-title>
            </v-card>  
          </v-hover>
        </template>
          <span>{{ item.birthday }}</span>
        </v-tooltip>
      </v-col>  
    </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn outlined text elevation="4" @click="loadMore" :disabled="count === 0">Mehr laden ({{ count }})</v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>
  
  <script>
  import IntroCard from './introCard.vue'
  export default {
    props:{
      data: Array,
    },
    data: () => ({
      babycards: [],
      page: 1,
      count: 0,
    }),
    name: 'BabyCard',
    mounted() {
      this.count = this.data.length - (this.page * 12);
      this.babycards = this.data.slice(0, this.page * 12);
    },
    methods: {
        babyview(id) {
            this.$router.push({path: `/babydetail/${id}`})
        },
        loadMore(){
          this.page++;
          if(this.data.length - (this.page * 12) < 0){
            this.count = 0;
          }else{
            this.count = this.data.length - (this.page * 12);
          }
          this.babycards = this.data.slice(0, this.page * 12);
        }
    },
  components: { IntroCard }
  }
  </script>
  