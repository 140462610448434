
const AWS = require('aws-sdk');

// Set up AWS credentials and configure the SDK
AWS.config.update({
  accessKeyId: 'AKIAT656PRE5AI36C6KE',
  secretAccessKey: 'K/o0QA4V4VBs4YKH0ZP0tWUGwr88sNpStTXmT+Jy',
  region: 'eu-central-1', // e.g., 'us-east-1'
});

// Create an S3 instance
export const s3 = new AWS.S3();

// Specify the bucket name and object key
export const bucketName = 'fotostudiosemler';
const objectKey = 'babys/baby.json';

// Create parameters for the getObject operation
export const babyKategorie = {
  Bucket: bucketName,
  Key: objectKey,
};
export const BabyPictures = {
    Bucket: bucketName,
    Key: objectKey,
}

export function encode(data) {
    var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
}

export const babyBauchKategorie = {
  Bucket: 'fotostudiosemler', /* required */
  Prefix: 'babybauch/'  // Can be your folder name
};

export const hochzeitKategorie = {
  Bucket: 'fotostudiosemler', /* required */
  Prefix: 'hochzeit/'  // Can be your folder name
};

export const familyKategorie = {
  Bucket: 'fotostudiosemler', /* required */
  Prefix: 'familie/'  // Can be your folder name
};

export const statisticsObj = {
  Bucket: bucketName,
  Key: `test/statistics.json`,
}
