<template>
    <v-row dense>
        <v-spacer class="desktop"></v-spacer>
        <v-col lg="8">
            <v-sheet elevation="12">  
                <h3>Berufsfotograf Gertraud Semler – FotoStudio Semler</h3><br/>
                <h4>Firmensitz:8274 Buch / St. Magdalena Österreich</h4>
                <h4>Gründungsjahr: 2009, Rechtsform EPU</h4>
                <h4>UID-Nummer: ATU568358902</h4>
                <h4>Behörde gem. ECG (E-Commerce Gesetz)</h4>
                <h4>Bezirkshauptmannschaft Hartberg-Fürstenfeld</h4><br/>

                    <h3>Copyright, Marken- und Schutzrechte</h3>             
                    <h4>
                Alle Inhalte auf dieser Website sind urheberrechtlich geschützt. Texte, Bilder, Grafiken, Sound, Animationen und Videos unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Der Inhalt darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden. Jede Nutzung, insbesondere die Speicherung in Datenbanken, Vervielfältigung, Verbreitung, Bearbeitung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte - auch in Teilen oder in überarbeiteter Form - ohne Zustimmung des Betreibers bzw. des Urhebers ist untersagt.
                    </h4> <br/> <h3>Nutzungsbedingungen, Gewährleistung, Haftung über Inhalt und Verlinkungen</h3><h4>
                Die Nutzung dieser Website erfolgt auf eigene Gefahr und auf eigenes Risiko. Wir übernehmen weder Gewähr für ständige Verfügbarkeit, noch für die veröffentlichten Beiträge, Angebote, Dienstleistungen und Services hinsichtlich Richtigkeit, Vollständigkeit und Funktionalität. Die Gewährleistung richtet sich nach den gesetzlichen Bestimmungen. Jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Webseite entstehen, sind ausgeschlossen. Diese Website und die damit verbundenen Dienste werden unter dem Gesichtspunkt größter Sorgfalt, Zuverlässigkeit und Verfügbarkeit betrieben. Aus technischen Gründen ist es jedoch nicht möglich, dass diese Dienste ohne Unterbrechung zugänglich sind, dass die gewünschten Verbindungen immer hergestellt werden können oder, dass gespeicherte Daten unter allen Gegebenheiten erhalten bleiben. Die ständige Verfügbarkeit kann daher nicht zugesichert werden. IP-Konnektivität zu anderen Netzbetreibern erfolgt nach Massgabe der Möglichkeiten. Jegliche Haftung für Probleme, die ihre Ursache in den Netzen Dritter haben, ist ausgeschlossen. Die Nutzung anderer Netze unterliegt den Nutzungsbedingungen der jeweiligen Betreiber. Bei höherer Gewalt, Streiks, Einschränkungen der Leistungen anderer Netzbetreiber oder bei Reparatur- und Wartungsarbeiten kann es zu Einschränkungen oder Unterbrechungen kommen, wobei für derartige Ausfälle keine Haftung besteht.
                </h4>
            </v-sheet>
        </v-col>
        <v-spacer class="desktop"></v-spacer>
    </v-row>
</template>


<script>
export default {
name: "ImpressumView",
}
</script>