<template>
  <div>
    <v-btn class="desktop button" left absolute @click="back()"> 
      <v-icon
      dark
      left
      >
      mdi-arrow-left
    </v-icon></v-btn>
    <BabySheet></BabySheet>
  </div>
</template>

<script>
import BabySheet from '../components/BabyDatasheet'
export default {
  data: () => ({
  }),
  name: 'BabyDetailView',
  components:{
    BabySheet
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
}
</script>