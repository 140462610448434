<template>
  <div>
      <v-btn class="desktop button" left absolute @click="back()"> 
          <v-icon
          dark
          left
          >
          mdi-arrow-left
        </v-icon></v-btn>
    <v-row dense class="paddingDesktop">
      <v-spacer class="desktop"></v-spacer>
      <v-col xs="12" md="8" lg="8">
        <introCardContact></introCardContact>
      </v-col>
      <v-spacer class="desktop"></v-spacer>
    </v-row>
  </div>
</template>

<script>
import IntroCardContact from './introCardContact.vue'
export default {
  name: 'ContactForm',
  methods:{
      back (){
        this.$router.back();
      }
    },
  components: { IntroCardContact }
}

</script>

<style scoped>
@media screen and (min-width: 600px) {
  .paddingDesktop {
    padding-top: 5vh;
  }
}


</style>