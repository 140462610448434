<template>
    <l-map  style="height: 30vh;" :zoom="zoom" :center="center">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker :lat-lng="markerLatLng"></l-marker>
    </l-map>
</template>
  
  <script>
  import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
  
  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker
    },
    data () {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 18,
        center: [47.228705, 15.987811],
        markerLatLng: [47.228705, 15.987811]
      };
    }
    // https://vue2-leaflet.netlify.app/components/LMap.html#demo
  }
  </script>

  <style>
  .map {
    padding-left: 25%;
    padding-right: 25%; 
  }

</style>
  
