<template>
    <v-card class="rounded-xl card" elevation="12">
        <v-row dense class="divider">
            <v-col xs="12" md="12" lg ="12">
                
                   <h2 style="text-align: center;">Liebe Eltern!</h2> 
                    <p class="text-justify">
                        Ich, Gertraud, bin Fotografin aus Leidenschaft und mit Meisterprüfung. <br/>

                        Die erste Zeit mit Eurem Baby zählt zu den emotionalsten Momenten in Eurem Leben. Und weil diese
                        Zeit viel zu schnell vergeht, ist es meine Passion, genau diese einzigartigen Augenblicke
                        dauerhaft festzuhalten. 
                        <br/>
                        In wundervollen Fotos, die Eure Erinnerungen auf ewig unterstützen
                        werden … 
                        <br/>
                        Ich freue mich auf euch!
                    </p>
             
            </v-col>
        </v-row>
        <v-divider class="divider"></v-divider>
        <v-row class="divider" dense>
            <v-col xs="12" md="12" lg="12">
                    <p style="text-align: center;">
                       
                        Servicezeiten auf der Station:
                        Montag, Mittwoch und Freitag Vormittag.
                        Andere Tage und Zeiten mit Terminvereinbarung.<br/> <v-icon>mdi-phone</v-icon>
      0664 24 20 668 
      <v-icon>mdi-email</v-icon> gsemler@gmx.at </p>
            </v-col>
        </v-row>
    </v-card>
</template>


<script>

export default {
  name: 'IntroCard',
}
</script>

<style scoped>

.image {
    border-radius: 25%;
    padding-left: 5px;
}

.card {
    padding-top: 5px;
}

.divider {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

</style>