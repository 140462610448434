<template>
    <v-card class="rounded-xl" elevation="12">
        <v-row dense class="divider">
            <v-col xs="12" md="12" lg ="12">
                <v-text>
                    <br/>
                       <h4 style="text-align: center;"> Das Wesen des Menschen bei der Aufnahme sichtbar zu machen, ist die höchste Kunst der Fotografie.  Friedrich Dürrenmatt</h4><br/>
<p style="text-align: center;">
Meine Liebe zur Fotografie wurde mit der Lehre in Hartberg zu meinem Beruf. Nach meiner Lehrabschlussprüfung ging ich nach Wien und konnte dort über ein Jahrzehnt berufliches Knowhow  in der Porträt- und Veranstaltungs- bzw Eventfotografie sammeln. Mit der Meisterprüfung legte ich den Grundstein für meine Selbständigkeit. 2009 konnte ich dann meinen Traum vom eigenen Fotostudio, hier in meiner Heimat, verwirklichen.<br/>

In meinem Studio oder in selbstgewählter Umgebung arbeite ich in lockerer Atmosphäre mit Ihnen daran, ihre Ideen zu verwirklichen. Gerne berate ich auf Basis meiner langjährigen Erfahrung bezüglich Location oder Hintergrundauswahl. Ein besonders großes Anliegen ist mir die Verwirklichung Ihrer Ideen und Wünsche und das sie mit dem Ergebnis zufrieden und glücklich sind.
   </p>
   <br/>
<h4 style="text-align: center;">“Fotografieren ist mehr als auf den Auslöser drücken.”</h4><br/>

                 
                </v-text>
            </v-col>
        </v-row>
        <v-divider class="divider"></v-divider>
        <v-row class="divider" dense>
            <v-col xs="12" md="12" lg="12">
                    <p style="text-align: center;">
    Terminvereinbarung<br/> <v-icon>mdi-phone</v-icon>
      0664 24 20 668 
      <v-icon>mdi-email</v-icon> gsemler@gmx.at </p>
            </v-col>
        </v-row>
    </v-card>
</template>


<script>

export default {
  name: 'IntroCardContact',
}
</script>

<style scoped>

.image {
    border-radius: 25%;
    padding-left: 5px;
}

.card {
    padding-top: 5vh;
}

.divider {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

</style>