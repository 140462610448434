<template>
    <ContactForm></ContactForm>
</template>

<script>
import ContactForm from '../components/ContactForm.vue'
export default {
  data: () => ({
  }),
  name: 'ContactView',
  components:{
    ContactForm
  }
}
</script>
