<template>
  <div>
    <v-container fluid>
      <v-row
      dense
      >
      <v-col 
      xs="6"
      md="6"
      lg="3"
      v-for="(item, index) in data" :key="index"
      >
      <v-hover v-slot="{ hover }">
        <v-card
        :class="{ 'on-hover': hover }"
      class="rounded-xl"
      :elevation="hover ? 12 : 2"
      :to="item.link"
      max-width="45vw"
    >
      <v-img :src="require(`@/assets/${item.img}`)" 
      ></v-img>
    </v-card>
  </v-hover>
    </v-col>
  </v-row>   
</v-container>
  </div>
  </template>

<script>
export default {
  data: () => ({
    overlay: false,
      zIndex: 0,
  }),
  props:{
    data: Array,
  },
  name: 'GaleryCard',
}
</script>
