<template>
  <div>
    <v-btn class="desktop button"  left absolute @click="back()"> 
        <v-icon
        dark
        left
        >
        mdi-arrow-left
      </v-icon></v-btn>
    <div>
      <v-container fluid>
      <v-row
        dense
      >
        <v-col xs="6" md="3"
        v-for="(item, index) of data" :key="index"
        >
        <v-sheet style="background-color: #f6f1ee;">
        <v-img
        :src="item"
        class="grey lighten-2 photo images"
        @click="overlayFunc(item, data)"
        ></v-img></v-sheet>
      </v-col>
    </v-row>
  </v-container>
    </div>
    <v-overlay
      :z-index="zIndex"
      :value="overlay"
      @click="overlay = false"
      opacity="0.95"
    >
    <v-window class="overlay">
    <v-carousel
    v-if="overlayImage"
    hide-delimiters
  hide-delimiter-background
  show-arrows-on-hover
  class="overlay"
  @click="overlay = !overlay"
  >
      <v-carousel-item class="overlay" v-for="(item, index) in this.overlayImage" :key="index">
        <v-img class="overlay" :src="item"></v-img>
      </v-carousel-item>
    </v-carousel></v-window>
    </v-overlay>
  </div>
    </template>
  
  <script>

  export default {
    data: () => ({
      overlay: false,
      overlayImage: Array,
    }),
    props:{
      data: Array,
    },
    name: 'ImageComponent',
    methods:{
      back() {
        this.$router.back();
      },
      overlayFunc(item, data) {
        this.overlay = true;
        this.overlayImage = data;
        let index = this.overlayImage.indexOf(item);
        this.overlayImage = this.overlayImage.slice(index).concat(this.overlayImage.slice(0, index));
      },
    },
  }
  </script>

  <style scoped>
  .photo {
    border-radius: 25px;
  }

  @media screen and (max-width: 600px) {
  .overlay {
    max-width: 90vw;
    max-height: 60vh;
    height: 400px;
  }

  .images {
    max-height: 45vh;
    max-width: 40vw;
  }
}

@media screen and (min-width: 600px) {
  
}

</style>
  